import React, { useState, useEffect } from 'react';
import { Users, Brain, Target, Network, MessageCircle, TrendingUp } from 'lucide-react';

function Projects() {
  const [isVisible, setIsVisible] = useState(false);

  const features = [
    {
      icon: Users,
      tag: "Talent Management",
      title: "Retain & Grow",
      description: "Transform employees into innovators with personalized growth paths"
    },
    {
      icon: Brain,
      tag: "Smart Platform",
      title: "AI-Powered Insights",
      description: "Make data-driven decisions to improve workplace dynamics"
    },
    {
      icon: Target,
      tag: "Efficiency",
      title: "Focus What Matters",
      description: "Streamline operations and eliminate redundant workflows"
    },
    {
      icon: Network,
      tag: "Community",
      title: "Build Connections",
      description: "Foster authentic workplace relationships and culture"
    },
    {
      icon: MessageCircle,
      tag: "Communication",
      title: "Engage Better",
      description: "Smart algorithms ensure your message reaches the right people"
    },
    {
      icon: TrendingUp,
      tag: "Growth",
      title: "Scale Culture",
      description: "Build a workplace that grows stronger with every addition"
    }
  ];

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="min-h-screen bg-black pt-16">
      <div className="max-w-[1440px] mx-auto px-6 sm:px-8 py-16">
        <div className={`transform transition-all duration-1000 delay-300 ease-out ${
          isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}>
          <div className="relative p-8 md:p-12 bg-white/[0.02] group hover:bg-white/[0.03] transition-all duration-300">
            <div className="relative z-10">
              {/* Header */}
              <div className="mb-12">
                <div className="inline-block px-4 py-1 text-sm mb-4 bg-white/10 text-white/80">
                  Professional Social Network
                </div>
                <h2 className="text-3xl md:text-4xl font-light text-white mb-4">
                  Daedal
                </h2>
                <p className="text-xl md:text-2xl text-white/70">
                  Reimagining workplace connectivity for tomorrow's companies
                </p>
              </div>

              {/* Features Grid */}
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                {features.map((feature, index) => (
                  <div 
                    key={index}
                    className={`transform transition-all duration-700 ease-out ${
                      isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                    }`}
                    style={{ transitionDelay: `${500 + index * 100}ms` }}
                  >
                    <div className="flex items-start space-x-4">
                      <div className="p-2 bg-white/5">
                        <feature.icon 
                          size={24}
                          className="text-white/80"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-medium mb-1 px-2 py-0.5 bg-white/5 text-white/60 inline-block">
                          {feature.tag}
                        </div>
                        <h3 className="text-base font-medium text-white mb-1">
                          {feature.title}
                        </h3>
                        <p className="text-sm text-white/60">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* CTA */}
              <div className="flex items-center space-x-6">
                <a
                  href="#contact"
                  className="inline-flex items-center group bg-white hover:bg-white/90 text-black px-8 py-3 transition-all duration-300"
                >
                  <span className="font-medium">Learn More</span>
                  <span className="ml-2 transform transition-transform duration-300 group-hover:translate-x-1">→</span>
                </a>
                <p className="text-white/60 text-sm">
                  Currently in development
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;