import { useState, useEffect } from 'react';
import websiteInfo from './../utils/websiteInfo';

function About() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="min-h-screen bg-black pt-16">
      <div className="max-w-[1440px] mx-auto px-6 sm:px-8 py-16">
        <div className={`transform transition-all duration-1000 delay-300 ease-out ${
          isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}>
          <div className="relative p-8 md:p-12 bg-white/[0.02] group hover:bg-white/[0.03] transition-all duration-300">
            <div className="relative z-10">
              {/* Header */}
              <div className="mb-12">
                <div className="inline-block px-4 py-1 text-sm mb-4 bg-white/10 text-white/80">
                  The Founders
                </div>
                <h2 className="text-3xl md:text-4xl font-light text-white mb-4">
                  Two minds, one vision
                </h2>
                <p className="text-xl md:text-2xl text-white/70 max-w-3xl">
                  Creating innovative solutions for tomorrow's challenges through technology and strategic thinking.
                </p>
              </div>

              {/* Founders Grid */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
                {/* Federico's Card */}
                <div className={`transform transition-all duration-700 ease-out ${
                  isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                }`}
                style={{ transitionDelay: '200ms' }}>
                  <div className="flex items-start space-x-4">
                    <div className="p-2 bg-white/5">
                      <div className="w-12 h-12 flex items-center justify-center">
                        <span className="text-white/80 text-sm">FT</span>
                      </div>
                    </div>
                    <div>
                      <div className="text-xs font-medium mb-1 px-2 py-0.5 bg-white/5 text-white/60 inline-block">
                        Strategic Director
                      </div>
                      <h3 className="text-lg font-medium text-white mb-3">
                        Federico Tarpini
                      </h3>
                      <p className="text-sm text-white/60 mb-4 leading-relaxed">
                        Passionate about politics and policy analysis, I consistently observe 
                        and analyze the world around me, striving to understand its complexities 
                        and patterns. My vision is to contribute meaningful solutions to societal 
                        challenges through Labyrica's innovative approach.
                      </p>
                      <p className="text-sm text-white/60 mb-6 leading-relaxed">
                        Currently working as a Consultant in European Funds for Public Sector, 
                        with expertise in Public Institutions, Public Affairs, and Research for 
                        Industrial and Energy policies.
                      </p>
                      <a href="mailto:tarpini.federico@gmail.com" 
                         className="inline-flex items-center group text-white/60 hover:text-white transition-colors duration-300">
                        <span className="text-sm">Contact</span>
                        <span className="ml-2 transform transition-transform duration-300 group-hover:translate-x-1">→</span>
                      </a>
                    </div>
                  </div>
                </div>

                {/* Roberto's Card */}
                <div className={`transform transition-all duration-700 ease-out ${
                  isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                }`}
                style={{ transitionDelay: '400ms' }}>
                  <div className="flex items-start space-x-4">
                    <div className="p-2 bg-white/5">
                      <div className="w-12 h-12 flex items-center justify-center">
                        <span className="text-white/80 text-sm">RB</span>
                      </div>
                    </div>
                    <div>
                      <div className="text-xs font-medium mb-1 px-2 py-0.5 bg-white/5 text-white/60 inline-block">
                        Technical Architect
                      </div>
                      <h3 className="text-lg font-medium text-white mb-3">
                        Roberto Bendinelli
                      </h3>
                      <p className="text-sm text-white/60 mb-4 leading-relaxed">
                        Focused on creating robust and scalable technical solutions that bridge 
                        the gap between complex requirements and elegant implementations. 
                        Specialized in developing intuitive interfaces and powerful backend systems.
                      </p>
                      <p className="text-sm text-white/60 mb-6 leading-relaxed">
                        With extensive experience in software architecture and system design, 
                        I bring a methodical approach to solving technical challenges while 
                        maintaining a strong focus on user experience and performance.
                      </p>
                      <a href="mailto:bendinelli.roberto@gmail.com" 
                         className="inline-flex items-center group text-white/60 hover:text-white transition-colors duration-300">
                        <span className="text-sm">Contact</span>
                        <span className="ml-2 transform transition-transform duration-300 group-hover:translate-x-1">→</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;