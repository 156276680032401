import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NetworkBackground from '../components/layout/NetworkBackground';

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className="relative min-h-screen w-full">
      {/* Darkened background for better readability on mobile */}
      <div className="absolute inset-0 bg-black/20 sm:bg-transparent"></div>
      
      <NetworkBackground />
      
      <main className="relative z-10 w-full min-h-screen">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-8">
          {/* Hero Section */}
          <div className="min-h-screen flex flex-col justify-center py-16 sm:py-24">
            <div className="space-y-8 sm:space-y-12">
              {/* Tag line */}
              <div className={`transform transition-all duration-700 delay-100 ${
                isLoaded ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
              }`}>
                <span className="inline-block bg-black/40 backdrop-blur-[6px] px-3 py-1.5 sm:px-4 sm:py-2
                  text-white text-sm sm:text-base tracking-wide">
                  DATA DRIVEN SOLUTIONS
                </span>
              </div>

              {/* Main title section */}
              <div className={`space-y-2 sm:space-y-3 transform transition-all duration-700 delay-300 ${
                isLoaded ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
              }`}>
                <div className="backdrop-blur-[4px] bg-black/40 inline-block px-2 py-1">
                  <h2 className="text-lg sm:text-xl font-light tracking-wide text-white/80">
                    OLD PROBLEMS
                  </h2>
                </div>
                <p></p>
                <div className="backdrop-blur-[4px] bg-black/40 inline-block px-2 py-1">
                  <h1 className="text-4xl sm:text-5xl font-light leading-tight 
                    tracking-tight text-white max-w-[12ch] sm:max-w-[14ch]">
                    INNOVATIVE SOLUTIONS
                  </h1>
                </div>
              </div>

              {/* Description */}
              <div className={`space-y-6 sm:space-y-8 transform transition-all duration-700 delay-500 ${
                isLoaded ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
              }`}>
                <div className="backdrop-blur-[4px] bg-black/40 inline-block px-2 py-1">
                  <p className="text-lg sm:text-xl text-white leading-relaxed font-light 
                    tracking-wide max-w-[40ch]">
                    Strategic Digital Tools for enabling innovators' potential.
                  </p>
                </div>
                
                <div className="space-y-4 sm:space-y-0 sm:flex sm:items-center 
                  sm:gap-8 text-white/80">
                  <div className="flex items-center space-x-2 transition-all duration-300 hover:text-white">
                    <span className="w-6 h-[1px] bg-white/40"/>
                    <span className="text-base sm:text-lg font-light backdrop-blur-[4px] bg-black/40 px-2">
                      We Imagine it
                    </span>
                  </div>
                  <div className="flex items-center space-x-2 transition-all duration-300 hover:text-white">
                    <span className="w-6 h-[1px] bg-white/40"/>
                    <span className="text-base sm:text-lg font-light backdrop-blur-[4px] bg-black/40 px-2">
                      We Build it
                    </span>
                  </div>
                  <div className="flex items-center space-x-2 transition-all duration-300 hover:text-white">
                    <span className="w-6 h-[1px] bg-white/40"/>
                    <span className="text-base sm:text-lg font-light backdrop-blur-[4px] bg-black/40 px-2">
                      You Grow
                    </span>
                  </div>
                </div>

                {/* CTA */}
                <div className={`pt-6 sm:pt-8 transform transition-all duration-700 delay-700 ${
                  isLoaded ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
                }`}>
                  <Link
                    to="/projects"
                    className="inline-flex items-center group/link relative
                      bg-white hover:bg-white/90 px-5 py-2.5 sm:px-6 sm:py-3 
                      transition-all duration-300"
                  >
                    <span className="text-black font-medium tracking-wider
                      text-base sm:text-lg uppercase">
                      Explore Our Solutions
                    </span>
                    <span className="ml-3 text-black transition-all duration-300
                      transform group-hover/link:translate-x-1">
                      →
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;