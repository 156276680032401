export default {
  "theme": {
    "_colorsDocs": "Tactical monochromatic palette with high contrast",
    "colors": {
      "primary": "#ffffff",      // Pure white
      "secondary": "#666666",    // Mid grey
      "accent": "#ffffff",       // White for emphasis
      "background": "#000000",   // Pure black
      "surface": "#111111",      // Slightly lighter black
      "text": {
        "primary": "#ffffff",
        "secondary": "#999999",
        "accent": "#cccccc"
      },
      "border": {
        "primary": "#222222",
        "secondary": "#333333"
      },
      "status": {
        "success": "#40F99B",    // Bright green for system status
        "error": "#FF3333",      // Red for errors
        "warning": "#F5A623"     // Amber for warnings
      },
      "overlay": {
        "modal": "rgba(0, 0, 0, 0.85)",
        "dropdown": "rgba(0, 0, 0, 0.95)"
      }
    },

    "_fontDocs": "Industrial typography optimized for data display",
    "font": {
      "primary": "'Inter', -apple-system, BlinkMacSystemFont, sans-serif",
      "code": "'SF Mono', Menlo, monospace",
      "sizes": {
        "xs": "0.75rem",
        "sm": "0.875rem",
        "base": "1rem",
        "lg": "1.125rem",
        "xl": "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem"
      },
      "lineHeight": {
        "tight": "1.2",
        "normal": "1.5",
        "relaxed": "1.7"
      },
      "letterSpacing": {
        "tight": "-0.02em",
        "normal": "0",
        "wide": "0.05em"
      }
    },

    "_spacingDocs": "Precise spacing system",
    "spacing": {
      "container": {
        "max": "1440px",
        "padding": {
          "default": "1.5rem",
          "sm": "2rem",
          "lg": "3rem"
        }
      },
      "grid": {
        "gap": "1.5rem",
        "columnGap": "2rem"
      }
    },

    "_borderRadiusDocs": "Minimal border radius for industrial look",
    "borderRadius": {
      "sm": "2px",
      "default": "3px",
      "lg": "4px",
      "xl": "6px"
    },

    "_styleDocs": "Tactical UI styling",
    "style": {
      "shadows": {
        "sm": "0 1px 2px rgba(0, 0, 0, 0.1)",
        "default": "0 2px 4px rgba(0, 0, 0, 0.1)",
        "lg": "0 4px 8px rgba(0, 0, 0, 0.1)"
      },
      "transitions": {
        "default": "all 0.2s ease",
        "fast": "all 0.1s ease",
        "slow": "all 0.3s ease"
      },
      "blur": {
        "default": "blur(8px)",
        "strong": "blur(16px)"
      }
    }
  },

  "_siteDocs": "Site metadata and branding",
  "site": {
    "name": "Labyrica",
    "description": "Tactical Digital Tools for Modern Innovators",
    "author": "Roberto Bendinelli",
    "links": {
      "github": "https://github.com/robertobendi",
      "documentation": "/docs"
    }
  },

  "_navigationDocs": "Streamlined navigation",
  "navigation": {
    "menu": [
      {
        "label": "Overview",
        "path": "/"
      },
      {
        "label": "About",
        "path": "/about"
      },
      {
        "label": "Solutions",
        "path": "/projects"
      },
    ]
  }
}