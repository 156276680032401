import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import websiteInfo from './../../utils/websiteInfo';
import logo from './../../assets/img/logo.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { theme } = websiteInfo;
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-200 ${
      scrolled ? 'bg-black/90' : 'bg-transparent'
    }`}>
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6">
        {/* Main navbar content */}
        <div className="flex items-center justify-between h-14 border-b border-white/5">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-3 group py-2">
            <img 
              src={logo} 
              alt="Labyrica Logo" 
              className="h-6 w-auto transition-transform duration-200 transform group-hover:scale-105"
              style={{ filter: 'brightness(1.1)' }}
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {websiteInfo.navigation.menu.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`relative py-4 text-sm tracking-wide transition-colors ${
                    isActive ? 'text-white' : 'text-gray-400 hover:text-white'
                  }`}
                >
                  <span>{item.label}</span>
                  {isActive && (
                    <span className="absolute bottom-0 left-0 w-full h-[1px] bg-white/80" />
                  )}
                </Link>
              );
            })}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden w-8 h-8 flex items-center justify-center -mr-2"
            aria-label="Toggle menu"
          >
            <div className="relative w-4 h-3">
              <span
                className={`absolute w-4 h-[1px] bg-white transform transition-all duration-200 ${
                  isOpen ? 'rotate-45 top-1.5' : 'rotate-0 top-0'
                }`}
              />
              <span
                className={`absolute w-4 h-[1px] bg-white top-1.5 transition-opacity duration-200 ${
                  isOpen ? 'opacity-0' : 'opacity-100'
                }`}
              />
              <span
                className={`absolute w-4 h-[1px] bg-white transform transition-all duration-200 ${
                  isOpen ? '-rotate-45 top-1.5' : 'rotate-0 top-3'
                }`}
              />
            </div>
          </button>
        </div>

        {/* Mobile Navigation */}
        <div
          className={`md:hidden absolute left-0 right-0 bg-black/95 backdrop-blur-sm transform transition-all duration-200 ease-in-out ${
            isOpen 
              ? 'translate-y-0 opacity-100 visible'
              : '-translate-y-4 opacity-0 invisible'
          }`}
        >
          <div className="py-2 space-y-0.5 border-b border-white/5">
            {websiteInfo.navigation.menu.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`block px-4 py-2 text-sm transition-colors ${
                    isActive 
                      ? 'text-white bg-white/5' 
                      : 'text-gray-400 hover:text-white hover:bg-white/5'
                  }`}
                  onClick={() => setIsOpen(false)}
                >
                  <div className="flex items-center justify-between">
                    <span>{item.label}</span>
                    {isActive && (
                      <span className="w-1 h-1 bg-white/80 rounded-full" />
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;