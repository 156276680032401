import websiteInfo from './../../utils/websiteInfo';

const defaultTheme = {
  colors: {
    background: '#000000',
    surface: '#1a1a1a',
    accent: '#3366ff',
    text: {
      primary: '#ffffff',
      secondary: '#a0a0a0',
      accent: '#3366ff'
    },
    border: {
      primary: 'rgba(255, 255, 255, 0.1)'
    }
  },
  style: {
    transitions: {
      fast: 'all 0.2s ease'
    }
  },
  font: {
    primary: 'system-ui',
    sizes: {
      sm: '0.875rem'
    }
  }
};

function Footer() {
  const { theme = defaultTheme, site = { links: { github: '#' } } } = websiteInfo || {};
  
  return (
    <footer style={{ 
      backgroundColor: theme.colors.background,
      borderTop: `1px solid ${theme.colors.border.primary}`,
      fontFamily: theme.font.primary,
      position: 'relative',
      zIndex: 9999
    }}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex flex-col md:flex-row justify-between items-center">
          <div style={{ 
            color: theme.colors.text.secondary,
            fontSize: theme.font.sizes.sm,
            opacity: 0.8
          }}>
            &copy; {new Date().getFullYear()} Federico Tarpini &amp; Roberto Bendinelli. All rights reserved.
          </div>
          
          <div className="mt-4 md:mt-0">
            <a
              href={site.links.github}
              style={{ 
                color: theme.colors.text.accent || theme.colors.accent,
                fontSize: theme.font.sizes.sm,
                transition: theme.style.transitions.fast
              }}
              className="hover:opacity-75"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;